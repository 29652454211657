<template>
  <ion-page ref="page">
    <ion-content :fullscreen="true" color="light">
      <div class="flex justify-center h-full">
        <div class="absolute inset-0">
          <video class="h-full w-full object-cover" autoplay loop playsinline>
            <source src="@/assets/videos/login-video.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          <div class="absolute inset-0 video-overlay"></div>
        </div>
        <div class="relative flex flex-col items-stretch justify-end px-26 gap-26 py-26">
          <div>
            <h1 class="text-center text-white py-26">
              Få information om lediga jobb, meddelanden och se din lönespecifikation!
            </h1>
          </div>
          <div>
            <div v-if="qrCode" class="flex justify-center mb-32">
              <div class="p-16 mx-auto bg-white rounded-md relative">
                <QRCode :code="qrCode" />
                <div
                  v-if="bankIdFailed"
                  class="absolute inset-0 z-10 flex justify-center items-center bg-black bg-opacity-50 cursor-pointer"
                  @click="() => startAuth()"
                >
                  <ion-icon aria-hidden="true" :icon="reload" class="h-64 w-64" />
                </div>
              </div>
            </div>
            <div v-if="bankIdMessage" class="text-center text-base mb-16 text-white">{{ bankIdMessage }}</div>
            <div v-else-if="loggingIn" class="text-center text-base mb-16 text-white">Loggar in...</div>
            <div>
              <Button :label="'Fortsätt med BankID'" :disabled="collecting || loggingIn" @click="startAuth" />
              <div class="flex w-full items-center my-12">
                <div class="border-b border-white flex-1 opacity-20"></div>
                <div class="text-center text-white text-xs mx-16 uppercase">Eller</div>
                <div class="border-b border-white flex-1 opacity-20"></div>
              </div>
              <Button :label="'Logga in med e-post'" :color="'light'" id="open-modal" />
            </div>
          </div>
          <div class="z-10 mt-26 px-24 flex justify-center" @click="testCounterIncrement">
            <img class="w-3/4 max-w-176" src="@/assets/images/logo-white.png">
          </div>
          <div class="text-sm text-center text-white pb-26 opacity-50">
            Genom att använda denna applikation godkänner du våra <a class="underline" href="https://veteranpoolen.se/anvandarvillkor">Användarvillkor</a> och <a class="underline" href="https://veteranpoolen.se/integritetspolicy">Integritetspolicy</a>
          </div>
        </div>
      </div>
      <ion-modal ref="logInModal" trigger="open-modal" :presenting-element="presentingElement">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button fill="clear" size="small" @click="dismissModal">
                <ion-icon slot="icon-only" :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content>
          <div class="mt-24 pb-56 px-26">
            <h3>
              Logga in med din e-post
            </h3>
            <div class="text-mutedText mt-12 mb-26">
              Har du glömt ditt lösenord? Vänligen kontakta ditt lokalkontor för återställningsuppgifter.
            </div>
            <ion-item>
              <ion-input label-placement="floating" v-model="email" type="email">
                <div slot="label">E-Post</div>
              </ion-input>
            </ion-item>
            <ion-item>
              <ion-input label-placement="floating" v-model="password" type="password">
                <div slot="label">Lösenord</div>
              </ion-input>
            </ion-item>
            <Button class="mt-12" :label="'Logga in'" :loading="loading" @click="startManualLogin" />
          </div>
        </ion-content>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script setup lang="ts">
import {IonPage, IonContent, IonIcon, IonInput, IonToolbar, IonButtons, IonButton, IonModal, IonHeader, IonItem} from '@ionic/vue';
import Button from '@/components/Button.vue';
import QRCode from "@/components/QRCode.vue";
import { useBankIdStore } from "@/stores/bankId";
import { storeToRefs } from "pinia";
import { reload, close } from "ionicons/icons";
import {onMounted, ref} from "vue";
import {useUserStore} from "@/stores/user";

const bankIdStore = useBankIdStore();
const userStore = useUserStore();
const { manualLogin } = userStore;
const { startBankIdAuth } = bankIdStore;
const { qrCode, bankIdMessage, bankIdFailed, collecting, loggingIn } = storeToRefs(bankIdStore);
const { loading } = storeToRefs(userStore);

const email = ref('');
const password = ref('');
const logInModal = ref();
const presentingElement = ref();
const page = ref();

const testCounter = ref(0);

const startAuth = (isTest?: boolean) => {
  startBankIdAuth(isTest);
};

const startManualLogin = async () => {
  const couldLogIn = await manualLogin({ email: email.value, password: password.value });
  if (couldLogIn) {
    dismissModal();
  }
};

const testCounterIncrement = () => {
  if (testCounter.value === 5) {
    startAuth(true);
  } else {
    testCounter.value++;
  }
};

const dismissModal = () => {
  logInModal.value.$el.dismiss();
};

onMounted(() => {
  presentingElement.value = page.value.$el;
})
</script>
<style scoped>
.video-overlay {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 72.5%), linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
}
</style>
